import { Message } from "element-ui"



/**
 * 自定义全局方法
 * params.options: 参数options为消息提示框字段
 * params.fn:    : 参数fn为消息框结束后的回调函数，可传。
 */
const plugin: any = {
  install(Vue: any, options: any) {
    let flag = true
    // 自定义全局消息提示方法，对elementui 的 Message做节流
    Vue.prototype.$popMsg = function (options: any, fn: any) {
      if (flag) {
        const duration = options.duration || 3000
        Message({
          showClose: options.showClose || false,
          message: options.message || "提示",
          type: options.type || "warning",
          duration: duration
        });
        setTimeout(() => {
          flag = true
          if (fn) {
            fn.bind(this)()
          }
        }, duration)
      }
      flag = false
    }
  }
}
export default plugin