<template>
  <div class="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default{
  name:'App',
  provide() { // 注册一个方法
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    resizeFun () {
      var info = navigator.userAgent;
        //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
        //如果包含“Mobile”（是手机设备）则返回true
      if(isPhone){
        //手机设备
      }else{
        var agent = navigator.userAgent.toLowerCase();
        var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        const devicePixelRatio = window.devicePixelRatio // 获取下载的缩放 125% -> 1.25    150% -> 1.5
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
          if (devicePixelRatio !== 1) {
            // 如果在笔记本中用IE浏览器打开 则弹出提示
            if (!! window.ActiveXObject || 'ActiveXObject' in window) {
              // alert('balabala。。。')
            } else {
              const c = document.querySelector('body')
              c.style.zoom = 1 / devicePixelRatio// 修改页面的缩放比例
            }
          }
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
          if (devicePixelRatio === 1.25 || devicePixelRatio === 1.5) {
            // 如果在笔记本中用IE浏览器打开 则弹出提示
            if (!! window.ActiveXObject || 'ActiveXObject' in window) {
              // alert('balabala。。。')
            } else {
              const c = document.querySelector('body')
              c.style.zoom = 1 / devicePixelRatio// 修改页面的缩放比例
            }
          }else{

          }
        }
        if(isMac){
            //your code 这是苹果mac系统
        }
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.resizeFun()
      window.addEventListener('resize', this.resizeFun)
    })
  }
}
</script>

<style lang="scss">
.app {
  height: 100%;
  width: 100%;
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(243, 241, 241);
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ddd9d9;
     border-radius: 10px;
  }
  hr {
    border: 0px;
    border-bottom: 1px solid rgb(204, 201, 201);
  }
}
</style>
