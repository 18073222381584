import Vue from 'vue'
import Vuex from 'vuex'
import baseUrl from '../config/baseUrl'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openTab: [{}],//所有打开的路由
    activeIndex: '组织架构', //也可以是默认激活路径；激活状态
    ExamRow: {},//题库管理中 选中行数据
    editExamid: '',//题库管理 编辑赛题所需ID
    testPaperId: '', // 试卷id
    ExamState: '',//题库管理id
     id:null,  // 比赛id
    paperId:null,//配置试卷id
    // baseURL: "http://172.30.6.110/", // 公共接口-环境
    baseURL: baseUrl, // 公共接口-本地
  },
  mutations: {
    // 添加tabs
    add_tabs(state, data) {
      state.openTab.push(data);
    },
    // 删除tabs
    delete_tabs(state, route) {
      let index = 0;
      for (let option of state.openTab) {
        // if (option.name === route.name) {
        //   break;
        // }
        index++;
      }
      state.openTab.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index(state, index) {
      state.activeIndex = index;
      // console.log(index,222);


    },
    //将题库管理中行数据传入
    addExamRow(state, index) {
      state.ExamRow = index.row;
    },
    //题库管理 编辑赛题传ID
    editExam(state, index) {
      state.editExamid = index;
    },
    // 编辑试卷保存id
    editPaper(state, id) {
      state.testPaperId = id
    },
    //新增赛题和编辑赛题后跳转题库管理的草稿箱
    changeExam(state, index) {
      state.ExamState = index;
    },
    //试卷配置存id,试卷id
    configpaper(state, data){
        state.id=data.id
        state.paperId=data.paperId
    }
  },

  actions: {
  },
  modules: {
  }
})
