const env = process.env.NODE_ENV
console.log(process.env)
const baseURL  = {
  development: 'http://172.30.6.110/',
  // development: 'http://172.30.4.150:8080/',
  // development: 'http://172.30.6.81/',
  production: location.origin + '/'
}

export default baseURL[env]



